import React, { useEffect, useContext, useState } from 'react'
import {
  EventDBContext,
  getMarketInfoString,
} from 'context/EventDBContext/EventDBContext'
import DonutChart from 'components/DonutChart/DonutChart'
import {
  DonutChartLabel,
  DonutChartValue,
} from 'components/DonutChart/DonutChart.styles'
import {
  Button,
  ChartLegend,
  ComponentWrapper,
  km_ify,
  AuthContext,
  Select,
  Text,
} from '@stokr/components-library'
import BasicTable from 'components/BasicTable/BasicTable'
import styled from 'styled-components'
import { Container, Chart, Legend, SelectWrapper } from './ChartBox.styles'
import NotificationsCarousel from 'components/NotificationsCarousel/NotificationsCarousel'
import { NotificationConsumer } from 'context/NotificationContext/NotificationContext'
import { DisplayOnBreakpoint } from 'styles/rwd'
import { TransactionTypes } from 'constants/enums'
import { getProjectCurrencySign } from '@stokr/components-library/dist/utils/formatCurrencyValue'
import ArtworkComponent from 'components/Artwork/ArtworkComponent'

const NoInvestmentChart = styled.div`
  width: 30%;
  float: left;
  padding-right: 5rem;

  @media screen and (max-width: 991px) and (min-width: 768px) {
    padding-right: 2rem;
  }

  @media screen and (max-width: 767px) and (min-width: 480px) {
    width: 100%;
  }

  @media screen and (max-width: 479px) {
    width: 100%;
    padding-right: 0;
  }
`

const NoInvestmentText = styled.div`
  width: 70%;
  float: left;
  padding-left: 5rem;
  padding-top: 20rem;

  h4 {
    width: 80%;
  }

  @media screen and (max-width: 1200px) and (min-width: 768px) {
    padding-top: 16rem;
    h4 {
      width: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    padding-left: 2rem;
    padding-top: 5rem;

    h4 {
      width: 100%;
    }
  }

  @media screen and (max-width: 479px) {
    width: 100%;
    padding-left: 0;
  }
`

const noData = [
  {
    color: '#f0f0f0',
    name: 'nothing found',
    price: 1,
    subname: 'no sub name',
    value: 102,
  },
]

const notificationTab = (isMobile = false, transactionsInProgress) => (
  <NotificationConsumer>
    {({ notifications, toggleIsRead }) => (
      <NotificationsCarousel
        dots
        isMobile={isMobile}
        onlyInvestmentNotifications
        transactionsInProgress={transactionsInProgress}
      />
    )}
  </NotificationConsumer>
)

const Table = (balance, symbol, price, address, link, label, projectName) => (
  <BasicTable
    data={[
      {
        label:
          projectName === 'techforgood' ? 'Donation amount' : 'Token balance',
        value: balance.toString(),
      },
      { label: 'Token price', value: `${symbol} ${price.toFixed(2)}` },
      {
        label: 'Token value',
        value: `${symbol} ${(balance * price).toFixed(2)}`,
      },
      { label: label, value: address, isLink: true, link },
    ]}
  />
)

const ChartBox = () => {
  const [transactionsInProgress, setTransactionsInProgress] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [noInvestments, setNoInvestments] = useState(false)
  const [fetchedBalances, setFetchedBalances] = useState()
  const [balances, setBalances] = useState()
  const [chartData, setChartData] = useState()
  const [dropdownOptions, setDropdownOptions] = useState([
    { key: '1', value: 'all', label: 'ALL ADDRESSES' },
  ])
  const [activeAddress, setActiveAddress] = useState({
    value: 'all',
    label: 'ALL ADDRESSES',
  })
  const [totalValue, setTotalValue] = useState(0)
  const { user } = useContext(AuthContext)
  const {
    tokenBalances,
    dollarInEuro,
    tokenTransfers,
    finalizedTx,
    pendingTx,
    hasArtwork,
  } = useContext(EventDBContext)

  useEffect(() => {
    const { wallets = [] } = user

    const genericWallets = wallets.filter((wallet) => !wallet.assetIds)

    // Generate dropdown list items
    const dropdownOptionsList = [
      { key: '1', value: 'all', label: 'ALL ADDRESSES' },
      ...genericWallets.map((wallet) => ({
        key: wallet.address,
        value: wallet.address,
        label: wallet.type === 'liquid' ? wallet.address : wallet.name,
      })),
    ]

    setDropdownOptions(dropdownOptionsList)

    //join  pending and finalized tx into one
    var transactions = [].concat(pendingTx, finalizedTx)

    //filter out secondary market tx and redemptions and note split tx
    transactions = transactions.filter(
      (tx) =>
        tx.type !== TransactionTypes.TRANSFER &&
        tx.type !== TransactionTypes.REDEMPTION &&
        tx.type !== TransactionTypes.NOTE_SPLIT &&
        !tx.isRedemption,
    )

    // sort array in descending order
    transactions.sort(function (item1, item2) {
      if (item1.createdAt > item2.createdAt) return -1
      if (item1.createdAt < item2.createdAt) return 1
      return 0
    })

    setTransactionsInProgress(transactions)

    // In case there are only pending euro tx older than 7 days
    setNoInvestments(transactions.length === 0)
  }, [user.wallets, tokenTransfers])

  useEffect(() => {
    const loadingCompleted = !!tokenBalances
    const balanceMoreThan0 =
      tokenBalances && tokenBalances.find((x) => x.balance > 0)
    const investmentsExist = loadingCompleted && balanceMoreThan0

    setIsLoading(!loadingCompleted)
    setNoInvestments(!investmentsExist)
    if (investmentsExist) {
      setFetchedBalances({ tokenBalances })
    }
  }, [tokenBalances])

  // Trigger after balances are load and/or selected address changes
  useEffect(() => {
    if (fetchedBalances) {
      // Generate table data
      var dataByTokenAddress = []

      //tokenBalnces hold all the required data
      fetchedBalances.tokenBalances.forEach((tokenAddress, index) => {
        const {
          balance,
          account,
          tokenName,
          issuanceType,
          marketData = {},
          tokenSymbol,
          tokenCurrency,
          tokenPrice,
          tokenPriceInEURorUSD,
          projectName,
        } = tokenAddress

        const tokenTotalBalance = balance
        let active =
          activeAddress.value === 'all' ? true : activeAddress.value === account

        //add data only if balance more than 0 and active adressses equals balance address
        if (tokenTotalBalance > 0 && active) {
          //define variables
          let value = parseFloat(
            tokenTotalBalance.toFixed(tokenAddress.tokenDecimals || 2),
          )

          let label = issuanceType === 'liquid' ? 'LIQUID ASSET ID' : ''

          const {
            isBTCIndex,
            lastPrice,
            sideSwapUrl,
            isPositive,
            changePercentage,
            last30DaysPriceChange,
            lastPriceCalculated,
          } = marketData

          //add data to array
          dataByTokenAddress.push({
            value,
            subname: tokenName,
            name: tokenSymbol,
            info: changePercentage
              ? `${
                  isBTCIndex ? '₿' : getProjectCurrencySign(tokenCurrency)
                }   ${
                  isBTCIndex
                    ? (value * lastPrice).toFixed(8)
                    : km_ify(value * (lastPriceCalculated || tokenPrice))
                }`
              : null,
            onNameClick: sideSwapUrl ? handleTokenNameClick : null,
            price: tokenPriceInEURorUSD,
            color: dataByTokenAddress.length % 2 === 0 ? '#0050ca' : '#ee220d',
            marketValue: changePercentage ? `${changePercentage} %` : '',
            marketInfo: last30DaysPriceChange
              ? getMarketInfoString(value, marketData, tokenCurrency)
              : null,
            isPositive: isPositive ?? null,
            marketData,
            details: Table(
              value,
              tokenCurrency === 'USD' ? '$' : '€',
              Number(tokenPrice),
              tokenAddress.asset,
              tokenAddress.blockhainLink,
              label,
              projectName,
            ),
          })

          let groupedDataMap = new Map()

          //group token address into one
          dataByTokenAddress.forEach((item) => {
            if (groupedDataMap.has(item.name)) {
              const existingItem = groupedDataMap.get(item.name)
              existingItem.value += item.value
              existingItem.info = changePercentage
                ? `${
                    isBTCIndex ? '₿' : getProjectCurrencySign(tokenCurrency)
                  } ${
                    isBTCIndex
                      ? (existingItem.value * lastPrice).toFixed(8)
                      : km_ify(value * (lastPriceCalculated || tokenPrice))
                  }`
                : null
              existingItem.marketInfo = last30DaysPriceChange
                ? getMarketInfoString(
                    existingItem.value,
                    marketData,
                    tokenCurrency,
                  )
                : null

              existingItem.details = Table(
                existingItem.value,
                tokenAddress.tokenCurrency === 'USD' ? '$' : '€',
                Number(tokenAddress.tokenPrice),
                tokenAddress.asset,
                tokenAddress.blockhainLink,
                label,
                projectName,
              )
            } else {
              groupedDataMap.set(item.name, { ...item })
            }
          })

          dataByTokenAddress = Array.from(groupedDataMap.values())
        }
      })

      setBalances(dataByTokenAddress)

      // Remove details to not break the chart
      const chartDataArray = dataByTokenAddress.map(
        ({ details, ...rest }) => rest,
      )
      setChartData(chartDataArray)

      setNoInvestments(dataByTokenAddress.length === 0)

      // Generate total value
      const totalValue = dataByTokenAddress.reduce(
        (accumulator, item) => (accumulator += item.price * item.value),
        0,
      )

      setTotalValue(totalValue.toFixed(2))
    }
  }, [activeAddress, fetchedBalances])

  const handleTokenNameClick = (e, data) => {
    e.preventDefault()
    e.stopPropagation()
    window.open(data.marketData?.sideSwapUrl, '_blank')
  }

  return (
    <>
      {!isLoading &&
        transactionsInProgress &&
        transactionsInProgress.length > 0 && (
          <ComponentWrapper center noPadding>
            <DisplayOnBreakpoint up breakpoint="Medium">
              {notificationTab(false, transactionsInProgress)}
            </DisplayOnBreakpoint>
            <DisplayOnBreakpoint down breakpoint="Medium">
              {notificationTab(true, transactionsInProgress)}
            </DisplayOnBreakpoint>
          </ComponentWrapper>
        )}
      <Container>
        {isLoading ? (
          <ComponentWrapper center>
            <Text blockchainLoadingMsg>
              <h4>
                A few seconds left. We are still reading the data out of the
                blockchain...
              </h4>
            </Text>
          </ComponentWrapper>
        ) : chartData && balances ? (
          <>
            {!noInvestments && (
              <Chart>
                <SelectWrapper>
                  <Select
                    id="addresses"
                    name={activeAddress.label}
                    value={activeAddress.value}
                    options={dropdownOptions}
                    label=""
                    onChange={(e) => {
                      setActiveAddress({
                        value: e.value,
                        label: e.label,
                      })
                    }}
                    onBlur={(e) => {}}
                  />
                </SelectWrapper>

                <DonutChart data={noInvestments ? noData : chartData}>
                  {!noInvestments && (
                    <>
                      <DonutChartLabel strong>Total value</DonutChartLabel>
                      <DonutChartValue fontSize="45">
                        {/* km_ifying only from 10.000, where the UI starts to break */}
                        € {km_ify(totalValue)}
                      </DonutChartValue>
                    </>
                  )}
                </DonutChart>
              </Chart>
            )}

            {noInvestments ? (
              <ComponentWrapper style={{ width: '100%' }}>
                <NoInvestmentChart>
                  <Chart>
                    <SelectWrapper>
                      <Select
                        id="addresses"
                        name={activeAddress.label}
                        value={activeAddress.value}
                        options={dropdownOptions}
                        label=""
                        onChange={(e) => {
                          setActiveAddress({
                            value: e.value,
                            label: e.label,
                          })
                        }}
                        onBlur={(e) => {}}
                      />
                    </SelectWrapper>

                    <DonutChart data={noData}>
                      <>
                        <DonutChartLabel strong>
                          No Investment Yet
                        </DonutChartLabel>
                        {/* <DonutChartValue fontSize="25">No Investment</DonutChartValue> */}
                      </>
                    </DonutChart>
                  </Chart>
                </NoInvestmentChart>
                <NoInvestmentText>
                  <Text>
                    <h4>
                      You have not made any investments yet. You want to see
                      what you can invest in? Please discover Investment
                      Opportunities.
                    </h4>
                  </Text>
                  <Button
                    style={{ marginTop: '3rem' }}
                    onClick={() =>
                      window.location.replace(
                        'https://stokr-staging.de/featured-assets',
                      )
                    }
                  >
                    INVESTMENT OPPORTUNITIES
                  </Button>
                </NoInvestmentText>
              </ComponentWrapper>
            ) : (
              <Legend spaceTop>
                <ChartLegend data={balances} />
              </Legend>
            )}
          </>
        ) : (
          <ComponentWrapper style={{ width: '100%' }}>
            <NoInvestmentChart>
              <Chart>
                <SelectWrapper>
                  <Select
                    id="addresses"
                    name={activeAddress.label}
                    value={activeAddress.value}
                    options={dropdownOptions}
                    label=""
                    onChange={(e) => {
                      setActiveAddress({
                        value: e.value,
                        label: e.label,
                      })
                    }}
                    onBlur={(e) => {}}
                  />
                </SelectWrapper>

                <DonutChart data={noData}>
                  <>
                    <DonutChartLabel strong>No Investment Yet</DonutChartLabel>
                    {/* <DonutChartValue fontSize="25">No Investment</DonutChartValue> */}
                  </>
                </DonutChart>
              </Chart>
            </NoInvestmentChart>
            <NoInvestmentText>
              <Text>
                <h4>
                  You have not made any investments yet. You want to see what
                  you can invest in? Please discover Investment Opportunities.
                </h4>
              </Text>
              <Button
                style={{ marginTop: '3rem' }}
                onClick={() =>
                  window.location.replace(
                    'https://stokr-staging.de/featured-assets',
                  )
                }
              >
                INVESTMENT OPPORTUNITIES
              </Button>
            </NoInvestmentText>
          </ComponentWrapper>
        )}
      </Container>

      {/* show artwork if invetsor has kpmg */}
      {!isLoading && chartData && balances && hasArtwork && (
        <ComponentWrapper style={{ paddingBottom: 64 }}>
          <ArtworkComponent
            // artSrc="https://res.cloudinary.com/stokr/image/upload/q_auto,f_auto/v1730015987/STO%20details/KPMG%20Artwork/Header/KPMG_Artwork-Tokenization_Sculpture_cydwzo.jpg"
            artSrc={`${process.env.PUBLIC_URL}/KPMG_Artwork.jpg`}
            artistName={'Artist: Ben Carter'}
            title={'Skatepark / City Scape / Industrial Heritage'}
          />
        </ComponentWrapper>
      )}
    </>
  )
}

export default ChartBox
